// Labels

@mixin label-variant($textColor) {
  background-color: $textColor;

  &[href] {
    &:hover,
    &:focus {
      background-color: darken($textColor, 10%);
    }
  }
}

// Progress bars

@mixin progress-bar-variant($textColor) {
  background-color: $textColor;

  // Deprecated parent class requirement as of v3.2.0
  .progress-striped & {
    @include gradient-striped;
  }
}

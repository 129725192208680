// Typography

// [converter] $parent hack
@mixin text-emphasis-variant($parent, $textColor) {
  #{$parent} {
    color: $textColor;
  }
  a#{$parent}:hover,
  a#{$parent}:focus {
    color: darken($textColor, 10%);
  }
}
